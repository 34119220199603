(function () {
  'use strict';

  angular
    .module('neo.home.facilities')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.facilities', {
        url: '/facilities',
        templateUrl: 'home/facilities/facilities.tpl.html',
        controller: 'FacilitiesCtrl',
        controllerAs: 'vm',
        resolve: {
          facilities : function (Facilities) {
            return Facilities.query().$promise
          },
          townhalls : function(Ajuntaments){
            return Ajuntaments.query().$promise;
          }
        }
      });
  }
}());
